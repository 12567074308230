<template>
  <div>
    <client-only>
      <common-notifications class="notifications" />
    </client-only>
    <slot></slot>
    <div class="footer">
      <footer-the-page-footer class="container" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import '~/assets/styles/gportal/variables';

$breakpoints: (
  md: 768px,
  xl: 1200px,
);

.notifications {
  z-index: 1056;
  margin-top: 4.5rem;
  width: 100%;
  max-width: 37rem;

  @include media-breakpoint-up(xl, $breakpoints) {
    margin-top: 0;
  }
}
</style>
